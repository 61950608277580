import { Box, Button, HStack, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import CampaingTable from './components/CampaingTable';
import { CampaignFilterInput, Campaing } from '../../models/campaing.model';
import { getCampaings, refreshCampaings } from '../../store/features/campaing/campaing.slice';
import { FiRefreshCcw } from 'react-icons/fi';
import CampaignFilter from '../../components/campaignFilter';
import { ModalCampaignSms } from './sms/ModalCampaignSms';
import { IoLogoWhatsapp } from 'react-icons/io5';
import { MdOutlineSms } from 'react-icons/md';
import { ChannelType } from '../../models/channel.model';
import { ModalCampaignWhatsapp } from './whatsapp/ModalCampaignWhatsapp';
import EmptyState from '../../components/emptyState/emptyState.component';
import { canCreateModule } from '../../store/features/company/company.slice';
import { CompanySubscriptionModuleType } from '../../models/company.model';

export default function Campaings() {
  const { isOpen: isOpenWhatsapp, onOpen: onOpenWhatsapp, onClose: onCloseWhatsapp, onToggle: onToggleWhatsapp } = useDisclosure();
  const { isOpen: isOpenSms, onOpen: onOpenSms, onClose: onCloseSms, onToggle: onToggleSms } = useDisclosure();
  const [filter, setFilter] = useState<CampaignFilterInput>({});
  const [selectedCampaign, setSelectedCampaign] = useState<Campaing | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { campaings, loading, pagination } = useAppSelector((state: RootState) => state.campaing);
  const [page, setPage] = useState(1);
  const [isFiltering, setIsfiltering] = useState<boolean>(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(refreshCampaings({ page, filter }));
    }, 3000);
    return () => clearInterval(intervalId);
  }, [page, filter]);

  useEffect(() => {
    dispatch(getCampaings({ page, filter }));
  }, [page]);

  useEffect(() => {
    dispatch(getCampaings({ page, filter }));
  }, [filter]);

  function updateFilter(newFilter: CampaignFilterInput) {
    setFilter(newFilter);
    if (newFilter && !Object.keys(newFilter).length) {
      setIsfiltering(false);
    }
  }

  function refresh() {
    dispatch(getCampaings({ page, filter }));
  }

  function toggleModal(channelType: ChannelType) {
    if (channelType === ChannelType.WHATSAPP) {
      onToggleWhatsapp();
    }
    if (channelType === ChannelType.SMS) {
      onToggleSms();
    }
  }

  useEffect(() => {
    console.log('selectedCampaign', selectedCampaign)
    if (selectedCampaign?.channelType === ChannelType.WHATSAPP) {
      onToggleWhatsapp();
    }
    if (selectedCampaign?.channelType === ChannelType.SMS) {
      onToggleSms();
    }
  }, [selectedCampaign]);

  function closeDrawer(updated: boolean, channelType: ChannelType) {
    if (channelType === ChannelType.WHATSAPP) {
      onCloseWhatsapp();
    }
    if (channelType === ChannelType.SMS) {
      onCloseSms();
    }
    setSelectedCampaign(undefined);
    if (updated) {
      dispatch(getCampaings({ page, filter }));
    }
  }

  function create(channelType: ChannelType) {
    if (channelType === ChannelType.SMS) {
      dispatch(canCreateModule({
          module: CompanySubscriptionModuleType.SMS,
          fn: () => {
            onToggleSms();
          },
        },
      ));
    } else {
      onToggleWhatsapp();
    }
  }

  return (
    <Box mt={{ base: '120px', md: '80px' }}>
      <HStack alignItems={'center'} mb={5} gap={10}>
        {!isFiltering ? <Button ml={3} rightIcon={<FiRefreshCcw />} colorScheme='blue' variant='link' onClick={refresh}>
          Atualizar
        </Button> : null}
        <CampaignFilter onFilter={updateFilter} loading={loading} onClick={() => setIsfiltering(true)} />
        {!isFiltering ? <Button variant='link' rightIcon={<IoLogoWhatsapp size={20}/>} colorScheme='blue' onClick={() => create(ChannelType.WHATSAPP)} size={'sm'}>
          Nova Whatsapp
        </Button> : null}
        {!isFiltering ? <Button variant='link' ml={5} mr={5} rightIcon={<MdOutlineSms size={20} />} colorScheme='blue' onClick={() => create(ChannelType.SMS)} size={'sm'}>
          Nova SMS
        </Button> : null}
      </HStack>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <EmptyState text={"Nenhuma campanha criada"} empty={campaings && !campaings.length} loading={loading} filtering={isFiltering} textFilter={'Nenhuma campanha encontrada'}>
          <CampaingTable
            pagination={pagination}
            tableData={campaings || []}
            onSelect={(l) => setSelectedCampaign(l)}
            onPageChange={v => setPage(v)}
            loading={loading}
          />
        </EmptyState>
      </SimpleGrid>
      {isOpenWhatsapp ? <ModalCampaignWhatsapp campaign={selectedCampaign} isOpen={isOpenWhatsapp}
                                  onClose={(updated: boolean) => closeDrawer(updated, ChannelType.WHATSAPP)} /> : null}
      {isOpenSms ? <ModalCampaignSms campaign={selectedCampaign} isOpen={isOpenSms}
                                  onClose={(updated: boolean) => closeDrawer(updated, ChannelType.SMS)} /> : null}
    </Box>
  );
}

import { wesendRequests } from '../wesend.api';
import { CleaningRobot } from '../../../models/cleaningRobot.model';
import { CreateLeadDto } from '../../../models/lead.model';
import { PaginatedResult } from '../lead/lead.dto.wesendapi';
import { RobotFilterInput, RobotStatusType } from '../../../models/robot.model';
import { CreateCleaningRobotDto } from './cleaningRobot.dto.wesendapi';

export const getCleaningRobots = async (data: RobotFilterInput & {
  // orderBy?: UserOrderByInput,
  page?: number,
}): Promise<PaginatedResult<CleaningRobot>> => {
  try {
    const path = 'cleaningRobot';
    const result = await wesendRequests.get(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const removeCleaningRobot = async (id: string): Promise<boolean> => {
  try {
    const path = `cleaningRobot/${id}`;
    const result = await wesendRequests.delete(path);
    return result;
  } catch (e) {
    throw e;
  }
};

export const changeCleaningRobotStatus = async (id: string, status: RobotStatusType): Promise<boolean> => {
  try {
    const path = `cleaningRobot/changeStatus/${id}`;
    const result = await wesendRequests.post(path, { status });
    return result;
  } catch (e) {
    throw e;
  }
};

export const createCleaningRobot = async (cleaningRobot: CreateCleaningRobotDto): Promise<CleaningRobot> => {
  try {
    const path = `cleaningRobot`;
    const result = await wesendRequests.post(path, cleaningRobot);
    return result;
  } catch (e) {
    console.log('createRobot', e)
    throw e;
  }
};

export const editCleaningRobot = async (cleaningRobot: CreateCleaningRobotDto): Promise<CleaningRobot> => {
  try {
    const path = `cleaningRobot/${cleaningRobot.id}`;
    const result = await wesendRequests.put(path, cleaningRobot);
    return result;
  } catch (e) {
    console.log('editRobot', e)
    throw e;
  }
};

export const createBatchCleaningRobot = async (typingRobotId: string, leads: Array<CreateLeadDto>): Promise<string> => {
  try {
    const path = `cleaningRobot/importLeads/${typingRobotId}`;
    const result = await wesendRequests.post(path, leads);
    return result;
  } catch (e) {
    console.log('createBatchRobot', e)
    throw e;
  }
};

export const exportCleaningRobotBatch = async (typingRobotId: string): Promise<string> => {
  try {
    const path = `cleaningRobot/exportBatch/${typingRobotId}`;
    const result = await wesendRequests.post(path, {});
    return result;
  } catch (e) {
    console.log('exportBatch', e)
    throw e;
  }
};

import { wesendRequests } from '../wesend.api';
import { PaginatedResult } from '../lead/lead.dto.wesendapi';
import { CreateIntegrationAuthDto } from './integrationAuth.dto.api';
import { IntegrationAuth } from '../../../models/integrationAuth.model';

export const getIntegrations = async (data: {
  // orderBy?: UserOrderByInput,
  page?: number,
}): Promise<PaginatedResult<IntegrationAuth>> => {
  try {
    const path = 'integrationAuth';
    const result = await wesendRequests.get(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const removeIntegration = async (id: string): Promise<boolean> => {
  try {
    const path = `integrationAuth/${id}`;
    const result = await wesendRequests.delete(path);
    return result;
  } catch (e) {
    throw e;
  }
};

export const createIntegration = async (integration: CreateIntegrationAuthDto): Promise<IntegrationAuth> => {
  try {
    const path = `integrationAuth`;
    const result = await wesendRequests.post(path, integration);
    return result;
  } catch (e) {
    console.log('createIntegration', e)
    throw e;
  }
};

export const editIntegration = async (id: string, integration: CreateIntegrationAuthDto): Promise<IntegrationAuth> => {
  try {
    const path = `integrationAuth/${id}`;
    const result = await wesendRequests.put(path, integration);
    return result;
  } catch (e) {
    console.log('editIntegration', e)
    throw e;
  }
};

import { CreateLeadDto, LeadProposalStatus } from './lead.model';
import { LeadFilterInput } from '../services/api/lead/lead.dto.wesendapi';

export enum BankType {
  C6 = 'C6',
  FACTA = 'FACTA',
  PARANA = 'PARANA'
}

const allTable: any = {
  '1860': {
    'codigo': '8',
    'empresa_id': '225',
    'banco_id': '626',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS Normal - Web',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2023-08-03 10:03:12',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'Banco C6',
    'produto_nome': 'FGTS',
  },
  '1861': {
    'codigo': '1',
    'empresa_id': '225',
    'banco_id': '389',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-08-03 10:30:16',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'MERCANTIL',
    'produto_nome': 'FGTS',
  },
  '1894': {
    'codigo': '46230',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS Light',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1113',
    'created_at': '2023-08-15 08:55:46',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '1906': {
    'codigo': '40010',
    'empresa_id': '225',
    'banco_id': '254',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS | 10 ANOS | TX MAIOR',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2023-09-01 09:47:15',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'PARANA BANCO',
    'produto_nome': 'FGTS',
  },
  '1913': {
    'codigo': '40762',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS FLEX RB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-09-06 09:52:16',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '1915': {
    'codigo': '1',
    'empresa_id': '225',
    'banco_id': '329',
    'produto': '7',
    'convenio': '100000',
    'nome': 'TOP ICRED',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2023-09-11 11:57:56',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'ICred',
    'produto_nome': 'FGTS',
  },
  '1916': {
    'codigo': '47635',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': ' FGTS FLEX 2 RB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-09-12 13:59:13',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '1919': {
    'codigo': '46213',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS PLUS RB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-09-13 12:22:38',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 1000,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '1978': {
    'codigo': '47627',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS FLEX 1 RB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-09-26 10:30:25',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '2006': {
    'codigo': '1',
    'empresa_id': '225',
    'banco_id': '41',
    'produto': '7',
    'convenio': '100000',
    'nome': 'Normal INSS',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-09-29 17:22:16',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'BANRISUL',
    'produto_nome': 'FGTS',
  },
  '2028': {
    'codigo': '8',
    'empresa_id': '225',
    'banco_id': '3291',
    'produto': '7',
    'convenio': '100000',
    'nome': 'Tabela Turbo (1.8% / mês)',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2023-10-09 11:51:37',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 50,
    'valor_maximo': 250,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'V8 Bank',
    'produto_nome': 'FGTS',
  },
  '2117': {
    'codigo': '900001',
    'empresa_id': '225',
    'banco_id': '623',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS PAN',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2023-10-20 14:34:57',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'BANCO PAN',
    'produto_nome': 'FGTS',
  },
  '2122': {
    'codigo': '9665',
    'empresa_id': '225',
    'banco_id': '318',
    'produto': '7',
    'convenio': '100000',
    'nome': 'SAQUE FGTS NORMAL',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1186',
    'created_at': '2023-10-23 14:52:49',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'BMG',
    'produto_nome': 'FGTS',
  },
  '2163': {
    'codigo': '230001',
    'empresa_id': '225',
    'banco_id': '626',
    'produto': '3',
    'convenio': '7000',
    'nome': 'E INSS Port Normal - Web',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2023-10-30 15:26:46',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'Banco C6',
    'produto_nome': 'Portabilidade',
  },
  '2164': {
    'codigo': '900006',
    'empresa_id': '225',
    'banco_id': '623',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS_CORBAN_FLEX1 - Taxa 1,99%',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-10-30 17:26:18',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'BANCO PAN',
    'produto_nome': 'FGTS',
  },
  '2294': {
    'codigo': '900007',
    'empresa_id': '225',
    'banco_id': '623',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS_CORBAN_FLEX2',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-11-09 09:11:41',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'BANCO PAN',
    'produto_nome': 'FGTS',
  },
  '2461': {
    'codigo': '40002',
    'empresa_id': '225',
    'banco_id': '254',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS | 3 ANOS',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2023-12-05 09:26:41',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'PARANA BANCO',
    'produto_nome': 'FGTS',
  },
  '2462': {
    'codigo': '10',
    'empresa_id': '225',
    'banco_id': '3291',
    'produto': '7',
    'convenio': '100000',
    'nome': 'Tabela Normal (1,8% / mês)',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2023-12-05 09:37:04',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'V8 Bank',
    'produto_nome': 'FGTS',
  },
  '2463': {
    'codigo': '40011',
    'empresa_id': '225',
    'banco_id': '254',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS | 10 ANOS | TX MENOR',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-12-05 09:43:57',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'PARANA BANCO',
    'produto_nome': 'FGTS',
  },
  '2516': {
    'codigo': '48291',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS LIGHT RB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-12-06 13:41:16',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': 10000,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '2517': {
    'codigo': '48275',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS TOP RB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-12-06 13:42:47',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 200,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '2574': {
    'codigo': '48585',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS SMART FLEX RB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2023-12-07 14:28:14',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 50,
    'valor_maximo': 550,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '2575': {
    'codigo': '53244',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS SMART LIGHT RB (50,00 a 60,00)',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2023-12-07 14:29:03',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 50,
    'valor_maximo': 60,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '2939': {
    'codigo': '40004',
    'empresa_id': '225',
    'banco_id': '254',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS | 4 ANOS',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-01-08 18:00:45',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'PARANA BANCO',
    'produto_nome': 'FGTS',
  },
  '2940': {
    'codigo': '40005',
    'empresa_id': '225',
    'banco_id': '254',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS | 5 ANOS',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-01-08 18:01:07',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'PARANA BANCO',
    'produto_nome': 'FGTS',
  },
  '2941': {
    'codigo': '40006',
    'empresa_id': '225',
    'banco_id': '254',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS | 6 ANOS',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-01-08 18:01:25',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'PARANA BANCO',
    'produto_nome': 'FGTS',
  },
  '2942': {
    'codigo': '40007',
    'empresa_id': '225',
    'banco_id': '254',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS | 7 ANOS',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-01-08 18:01:52',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'PARANA BANCO',
    'produto_nome': 'FGTS',
  },
  '2943': {
    'codigo': '40008',
    'empresa_id': '225',
    'banco_id': '254',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS | 8 ANOS',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2024-01-08 18:02:23',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'PARANA BANCO',
    'produto_nome': 'FGTS',
  },
  '2944': {
    'codigo': '40009',
    'empresa_id': '225',
    'banco_id': '254',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS | 9 ANOS',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2024-01-08 18:02:43',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'PARANA BANCO',
    'produto_nome': 'FGTS',
  },
  '3147': {
    'codigo': '210012',
    'empresa_id': '225',
    'banco_id': '626',
    'produto': '1',
    'convenio': '7000',
    'nome': 'INSS ML NORMAL-WEB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-01-22 18:22:59',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 800,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'Banco C6',
    'produto_nome': 'Margem Livre',
  },
  '3226': {
    'codigo': '50059',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '1',
    'convenio': '7000',
    'nome': 'INSS NOVO SMART 5K PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-01-29 10:03:41',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 800,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Margem Livre',
  },
  '3332': {
    'codigo': '50407',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'LIGHT 2 (  Maior de  R$1000,00)',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-02-14 12:05:44',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 100,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '4262': {
    'codigo': '48356',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '3',
    'convenio': '7000',
    'nome': '\tINSS PORT CIP FLEX 1 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:45:24',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Portabilidade',
  },
  '4263': {
    'codigo': '45047',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '3',
    'convenio': '7000',
    'nome': '\tINSS CIP Flex 2 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:46:02',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Portabilidade',
  },
  '4264': {
    'codigo': '45721',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': '\tRefin CIP R Gold Normal PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:46:25',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4265': {
    'codigo': '50415',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': 'INSS CIP REFIN GOLD LOAS PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:46:39',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4266': {
    'codigo': '50431',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': 'INSS CIP REFIN GOLD LOAS FLEX 1 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:46:50',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4267': {
    'codigo': '46590',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': '\tINSS Refin CIP Gold Flex 1 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:46:59',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4268': {
    'codigo': '46604',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': 'INSS Refin CIP Gold Flex 2 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:47:11',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4269': {
    'codigo': '50458',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': 'INSS CIP REFIN GOLD LOAS FLEX 2 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:47:22',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4270': {
    'codigo': '50474',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': 'INSS CIP REFIN GOLD LOAS FLEX 3 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:47:33',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4271': {
    'codigo': '47198',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': 'Refin CIP R Gold Flex 3 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:47:43',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4272': {
    'codigo': '48313',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': 'INSS CIP REFIN GOLD FLEX 4 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:47:54',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4273': {
    'codigo': '50490',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': 'INSS CIP REFIN GOLD LOAS FLEX 4 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:48:03',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4274': {
    'codigo': '52892',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': 'INSS CIP REFIN GOLD FLEX 5 PN-RB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:48:16',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4275': {
    'codigo': '52930',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '9',
    'convenio': '7000',
    'nome': 'INSS CIP REFIN GOLD LOAS FLEX 5 PN-RB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 17:48:28',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Refin da Port',
  },
  '4276': {
    'codigo': '45101',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '3',
    'convenio': '7000',
    'nome': 'INSS CIP FLEX 8 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 18:01:41',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Portabilidade',
  },
  '4277': {
    'codigo': '48410',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '3',
    'convenio': '7000',
    'nome': 'INSS PORT CIPL FLEX 7 PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-13 18:02:01',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Portabilidade',
  },
  '4338': {
    'codigo': '45055',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '3',
    'convenio': '7000',
    'nome': 'INSS CIP FLEX 3 PN-S grupo 2',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-14 17:15:10',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Portabilidade',
  },
  '4339': {
    'codigo': '45080',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '3',
    'convenio': '7000',
    'nome': 'INSS CIP FLEX 6 PN-S GRUPO 2',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-03-14 17:15:32',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Portabilidade',
  },
  '4696': {
    'codigo': '53236',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS GOLD RN - 840 a 3000',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-04-02 22:58:01',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 840,
    'valor_maximo': 3000,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '4960': {
    'codigo': '53252',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS SMART FLEX RB ( 50,00 a 60,00)',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-04-12 09:06:06',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 50,
    'valor_maximo': 60,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '5105': {
    'codigo': '52574',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '1',
    'convenio': '7000',
    'nome': 'INSS NOVO LOAS 87 PN-S\t',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-04-16 13:59:14',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 500,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Margem Livre',
  },
  '5271': {
    'codigo': '52590',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '1',
    'convenio': '7000',
    'nome': 'INSS NOVO LOAS 87 GOLD RL RB',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-04-18 12:09:44',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Margem Livre',
  },
  '5671': {
    'codigo': '2',
    'empresa_id': '225',
    'banco_id': '389',
    'produto': '7',
    'convenio': '100000',
    'nome': '',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2024-04-25 12:42:28',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 500,
    'valor_maximo': 50000,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'MERCANTIL',
    'produto_nome': 'FGTS',
  },
  '6029': {
    'codigo': '53260',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'SMART CRED ESTADOS - (R$50 a 150)',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2024-05-06 10:46:32',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 50,
    'valor_maximo': 150,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '6206': {
    'codigo': '632',
    'empresa_id': '225',
    'banco_id': '3295',
    'produto': '7',
    'convenio': '100000',
    'nome': 'SAQUE - FGTS - BFFUNDO - TX 1,80 - NORMAL',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-05-08 08:46:05',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 15,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'Fox Digital',
    'produto_nome': 'FGTS',
  },
  '7244': {
    'codigo': '53678',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FACTA  EXTRA -  (50 - 75)- (175 - 1000)',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-05-17 14:04:33',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 50,
    'valor_maximo': 1000,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '7315': {
    'codigo': '53686',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FACTA EXTRA 400-900',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2024-05-19 17:14:31',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 400,
    'valor_maximo': 900,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '7756': {
    'codigo': '663981',
    'empresa_id': '225',
    'banco_id': '324',
    'produto': '7',
    'convenio': '100000',
    'nome': 'TABELA XX',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-05-22 12:25:40',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 70,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'Lotus Mais',
    'produto_nome': 'FGTS',
  },
  '7757': {
    'codigo': '1039733',
    'empresa_id': '225',
    'banco_id': '324',
    'produto': '7',
    'convenio': '100000',
    'nome': 'TABELA IX',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2024-05-22 12:25:57',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'Lotus Mais',
    'produto_nome': 'FGTS',
  },
  '7758': {
    'codigo': '206166',
    'empresa_id': '225',
    'banco_id': '324',
    'produto': '7',
    'convenio': '100000',
    'nome': 'TABELA PRIME',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2024-05-22 12:26:13',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'Lotus Mais',
    'produto_nome': 'FGTS',
  },
  '13826': {
    'codigo': '53732',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '1',
    'convenio': '7000',
    'nome': 'INSS NOVO SMART PN-S',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-07-24 16:41:43',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 1000,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Margem Livre',
  },
  '17194': {
    'codigo': '53694',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'UP - (900 a 2000)',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-09-04 17:17:08',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 900,
    'valor_maximo': 2100,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '17195': {
    'codigo': '53210',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'CRED ESTADOS PLUS ( R$2000,00)',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-09-04 17:17:52',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 2000,
    'valor_maximo': 2000000,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '17281': {
    'codigo': '56073',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'EXTRA 50 - 2000',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-09-06 09:57:36',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 50,
    'valor_maximo': 2000,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '19345': {
    'codigo': '56081',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'EXTRA 2',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2024-09-30 11:35:47',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 50,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '19346': {
    'codigo': '56090',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '7',
    'convenio': '100000',
    'nome': 'EXTRA 3',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '0',
    'created_user_id': '1110',
    'created_at': '2024-09-30 11:36:04',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 50,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'FACTA',
    'produto_nome': 'FGTS',
  },
  '20054': {
    'codigo': '638',
    'empresa_id': '225',
    'banco_id': '3295',
    'produto': '7',
    'convenio': '100000',
    'nome': 'FGTS 2 - FOX',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-10-09 17:28:16',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 10,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'Fox Digital',
    'produto_nome': 'FGTS',
  },
  '20055': {
    'codigo': '939',
    'empresa_id': '225',
    'banco_id': '3295',
    'produto': '7',
    'convenio': '100000',
    'nome': 'TABELA 3 - FOX',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-10-09 17:29:18',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': 10,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'Caixa Econômica',
    'banco_nome': 'Fox Digital',
    'produto_nome': 'FGTS',
  },
  '20632': {
    'codigo': '53767',
    'empresa_id': '225',
    'banco_id': '935',
    'produto': '1',
    'convenio': '7000',
    'nome': 'LOAS 87',
    'coeficiente_medio': null,
    'prazo_inicio': null,
    'prazo_fim': null,
    'flag_aumento': false,
    'exibir': '1',
    'created_user_id': '1110',
    'created_at': '2024-10-16 16:07:20',
    'prazos': [],
    'coeficiente_online': true,
    'prioritaria': false,
    'franquias': '[]',
    'valor_minimo': null,
    'valor_maximo': null,
    'franquias_habilitadas': [],
    'convenio_nome': 'INSS',
    'banco_nome': 'FACTA',
    'produto_nome': 'Margem Livre',
  },
};

export const BankTables = Object.values(allTable).filter((t: any) => t.banco_nome === 'FACTA').map((t: any) => t.codigo).sort();

export const BankTypeName = {
  [BankType.C6]: 'C6',
  [BankType.FACTA]: 'FACTA',
  [BankType.PARANA]: 'PARANÁ',
};

export enum RobotStatusType {
  CREATNG = 'CREATNG',
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  ERROR = 'ERROR',
  LOGIN_ERROR = 'LOGIN_ERROR',
  BANNED_IP = 'BANNED_IP',
  FINISHED = 'FINISHED',
}

export const RobotStatusTypeName = {
  [RobotStatusType.CREATNG]: 'CRIANDO',
  [RobotStatusType.RUNNING]: 'EXECUTANDO',
  [RobotStatusType.STOPPED]: 'PARADO',
  [RobotStatusType.ERROR]: 'ERRO',
  [RobotStatusType.LOGIN_ERROR]: 'FALHA DE LOGIN',
  [RobotStatusType.BANNED_IP]: 'IP BANIDO',
  [RobotStatusType.ERROR]: 'ERRO',
  [RobotStatusType.FINISHED]: 'FINALIZADO',
};

export enum ProductType {
  FGTS = 'FGTS'
}

export const ProductTypeName = {
  [ProductType.FGTS]: 'FGTS',
};

export type Robot = {
  id?: string
  type: BankType
  name: string
  // companyId: string
  createdAt?: Date | string
  updatedAt?: Date | string
  deleted?: boolean
  deletedAt?: Date | string | null
  errorAttemps?: number
  concurrency?: number
  leadFilter?: LeadFilterInput
  leadFilterId?: string | null
  login: string
  password: string
  status?: RobotStatusType
  reference?: number
  product?: ProductType
  deployName?: string | null
  simulationsLength?: number | null
  simulationsCreditLength?: number | null
  simulationsFailLength?: number | null
  simulationsWithoutPermissionLength?: number | null
  simulationsNoCreditLength?: number | null
  startedAt?: Date | string | null
  stoppedAt?: Date | string | null
  scheduled?: boolean
  startTime?: Date
  endTime?: Date
  simulationLimit?: number
  batchLength?: number
  limitedSimulation?: boolean
  retryFails?: boolean
  batchLeads: Array<CreateLeadDto>;
  tableNumber: number;
}

export interface RobotFilterInput {
  type?: BankType;
  name?: string;
  login?: string;
  status?: Array<RobotStatusType>;
  product?: ProductType;
}

import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Progress,
  SimpleGrid,
  Spacer,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import { BankTables, BankType, ProductType, RobotStatusType, RobotStatusTypeName } from '../../../models/robot.model';
import { TbHandStop } from 'react-icons/tb';
import { BiRefresh, BiTrashAlt } from 'react-icons/bi';
import { showToast } from '../../../services/toast.service';
import * as yup from 'yup';
import { InputText } from '../../../components/inputText/inputText.component';
import { InputNumber } from '../../../components/inputNumber/inputNumber.component';
import { FaPlay } from 'react-icons/fa6';
import { CreateLeadDto, statusColor } from '../../../models/lead.model';
import ConfirmationButton from 'components/buttons/RemoveConfirmation';
import { InputCheckTimeRange } from '../../../components/inputCheckTimeRange/inputCheckTimeRange.component';
import { InputCheckNumber } from '../../../components/inputCheckNumber/inputCheckNumber.component';
import { TypingRobot } from '../../../models/typingRobot.model';
import {
  changeTypingRobotStatus,
  clearTypingImportationResult,
  createTypingRobot,
  editTypingRobot,
  exportTypingBatch,
  getImportStatus,
  removeTypingRobot,
} from '../../../store/features/typingRobots/typingRobots.slice';
import { format } from 'date-fns';
import ModalTypingCreateBatch from './ModalTypingCreateBatch';
import AlertHelper from '../../../components/alertHelper/bankIcon.component';
import { InputSelect } from '../../../components/inputSelect/inputSelect.component';
import { InputCheckSelect } from '../../../components/inputCheckSelect/inputCheckTimeRange.component';
import { getIntegrations } from '../../../store/features/integration/integration.slice';
import { IntegrationAuth, IntegrationAuthTypeTranslation } from '../../../models/integrationAuth.model';

interface IModalRobot {
  robot: TypingRobot | undefined;
  isOpen: boolean;
  onClose: (updated: boolean) => void;
}

export function ModalTypingRobot({ robot, isOpen, onClose }: IModalRobot) {
  const { isOpen: isOpenModalCreateBatch, onOpen: onOpenModalCreateBatch, onClose: onCloseModalCreateBatch } = useDisclosure();
  const { loadingUpdate, error, importPercent, loadingImporting, importResult } = useAppSelector((state: RootState) => state.typingRobot);
  const [leadsToImport, setLeadsToImport] = useState<Array<CreateLeadDto>>([]);
  const dispatch = useAppDispatch();
  const [importId, setImportId] = useState<string>(null);
  const { integrations } = useAppSelector((state: RootState) => state.integration);
  const [integrationList, setIntegrationList] = useState<Array<{ id: string, label: string, tag: string }>>([]);
  console.log('ModalTypingRobot', robot?.id)
  useEffect(() => {
    dispatch(getIntegrations({ page: 1 }));
  }, []);
  useEffect(() => {
    if (integrations) {
      setIntegrationList(integrations.map((i: IntegrationAuth) => {
        return { id: i.id, label: i.name, tag: IntegrationAuthTypeTranslation[i.type]};
      }));
    }
  }, [integrations]);
  const { handleSubmit, handleChange, values, resetForm, errors, setFieldValue, dirty } = useFormik({
    initialValues: {
      id: robot?.id,
      name: robot?.name,
      login: robot?.login,
      password: robot?.password,
      type: robot?.type || BankType.FACTA,
      product: robot?.product || ProductType.FGTS,
      errorAttemps: robot?.errorAttemps || 10,
      concurrency: robot?.concurrency || 1,
      scheduled: robot?.scheduled || false,
      startTime: robot?.startTime || new Date(),
      endTime: robot?.endTime || new Date(),
      simulationLimit: robot?.simulationLimit || 1000,
      limitedSimulation: robot?.limitedSimulation || false,
      tableNumber: robot?.tableNumber || 56073,
      tax: robot?.tax || 1.8,
      batchLeads: [],
      createProposal: robot?.createProposal || false,
      integrationAuthId: robot?.integrationAuthId || null,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Campo obrigatório"),
      login: yup.string().required("Campo obrigatório"),
      password: yup.string().required("Campo obrigatório"),
      tableNumber: yup.string().required("Campo obrigatório"),
      limit: yup.number().max(50).min(1),
    }),
    onSubmit: async (values) => {
      if (values?.createProposal && !values?.integrationAuthId) {
        return;
      }
      if (!robot?.id) {
        const response: any = await dispatch(createTypingRobot({ ...values } as TypingRobot));
        console.log('response', response)
        if (response.error) {
          showToast('error', response.error.message);
        } else {
          setImportId(response.payload);
        }
      } else {
        const response: any = await dispatch(editTypingRobot({ ...values } as TypingRobot));
        if (response.error) {
          showToast('error', response.error.message);
        } else if (response.payload) {
          setImportId(response.payload);
        } else {
          onCreatedRobot();
        }
      }
    },
  });

  useEffect(() => {
    let importStatusInterval: string | number | NodeJS.Timeout;
    if (importId) {
      importStatusInterval = setInterval(() => {
        dispatch(getImportStatus(importId));
      }, 1000);
    } else {
      clearInterval(importStatusInterval);
    }
    return () => clearInterval(importStatusInterval);
  }, [importId]);

  useEffect(() => {
    setFieldValue('batchLeads', leadsToImport);
  }, [leadsToImport]);

  useEffect(() => {
    if (error) {
      showToast('error', error);
    }
  }, [error]);

  useEffect(() => {
    if (importResult) {
      setImportId(null);
      onCreatedRobot();
    }
  }, [importResult]);

  function onCreatedRobot() {
    showToast('success', 'Robo criada!');
    dispatch(clearTypingImportationResult());
    closeModal(true);
  }

  async function remove() {
    const response = await dispatch(removeTypingRobot({
      id: robot.id,
    }));
    if (response.payload) {
      showToast('success', 'Robô removido!');
      onClose(true);
    } else {
      showToast('error', error);
    }
  }

  async function updateStatus(status: RobotStatusType) {
    const response = await dispatch(changeTypingRobotStatus({
      id: robot.id,
      status,
    }));
    if (response.payload) {
      showToast('success', `Alterado!`);
      onClose(true);
    } else {
      showToast('error', error);
    }
  }

  function closeModal(updated: boolean) {
    resetForm();
    onClose(updated);
  }

  async function baseDownload() {
    const result = await dispatch(exportTypingBatch(robot?.id));
    if (result.payload) {
      const a = document.createElement('a');
      const blob = new Blob([result.payload as ArrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `[wesend] Leads digitacao exportados ${format(new Date(), 'dd/MM/yyyy-HH:mm')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  return (
    <Drawer
      size={'xl'}
      isOpen={isOpen}
      placement='right'
      onClose={() => closeModal(false)}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {robot?.name || 'Novo robô'}
          <Tag
            ml={5}
            borderRadius="full"
            variant="solid"
            size={'lg'}
            colorScheme={statusColor[robot?.status]}
          >
            <TagLabel>{RobotStatusTypeName[robot?.status]}</TagLabel>
          </Tag>
        </DrawerHeader>
        <DrawerBody>
          <Box borderWidth='1px' borderRadius='lg' p={5} overflow='hidden' mb={5}>
            <SimpleGrid columns={1} spacing={{ base: '20px', xl: '20px' }}>
              <ModalTypingCreateBatch
                onClose={onCloseModalCreateBatch}
                onOpen={onOpenModalCreateBatch}
                isOpen={isOpenModalCreateBatch}
                onUpload={(leads) => setLeadsToImport(leads)}
              />
              <InputText
                label='Nome do robô'
                value={values?.name}
                onChange={handleChange('name')}
                error={errors.name}
              />
              <InputText
                label='Login'
                value={values?.login}
                onChange={handleChange('login')}
                error={errors.login}
              />
              <InputText
                label='Senha'
                value={values?.password}
                onChange={handleChange('password')}
                error={errors.password}
              />
              {/*<InputSelect*/}
              {/*  key={'bank-select'}*/}
              {/*  label='Banco'*/}
              {/*  placeholder='Banco'*/}
              {/*  value={values?.type}*/}
              {/*  onChange={handleChange('type')}*/}
              {/*  error={errors.type}*/}
              {/*  options={Object.values(BankType).map(v => {*/}
              {/*    return {*/}
              {/*      label: BankTypeName[v],*/}
              {/*      value: v,*/}
              {/*    };*/}
              {/*  })}*/}
              {/*/>*/}
              <InputSelect
                key={'bank-table'}
                label='Tabela'
                placeholder='Tabela'
                value={String(values?.tableNumber)}
                onChange={(n) => setFieldValue('tableNumber', parseInt(n))}
                error={errors.tableNumber}
                options={BankTables.map(v => {
                  return {
                    label: String(v),
                    value: String(v),
                  };
                })}
              />
              <InputNumber
                label='Taxa'
                value={values?.tax}
                onChange={(n) => setFieldValue('tax', n)}
                error={errors.tax}
                min={0}
                max={100}
                precision={1}
                step={0.1}
              />
             {/* <InputSelect
                key={'bank-select'}
                label='Banco'
                placeholder='Banco'
                value={values?.type}
                onChange={handleChange('type')}
                error={errors.type}
                options={Object.values(BankType).map(v => {
                  return {
                    label: BankTypeName[v],
                    value: v,
                  };
                })}
              />*/}
              {/*<InputSelect
                key={'product-select'}
                label='Produto'
                placeholder='Produto'
                value={values?.type}
                onChange={handleChange('product')}
                error={errors.product}
                options={Object.values(ProductType).map(v => {
                  return {
                    label: ProductTypeName[v],
                    value: v,
                  };
                })}
              />*/}
              <InputNumber
                label='Quantidade de tentativas por lead'
                value={values?.errorAttemps}
                onChange={(n) => setFieldValue('errorAttemps', n)}
                error={errors.errorAttemps}
                min={1}
                max={100}
              />
              <InputNumber
                label='Simulações em paralelo'
                value={values?.concurrency}
                onChange={(n) => setFieldValue('concurrency', n)}
                error={errors.concurrency}
                min={1}
                max={10}
              />
             {/* <InputCheckTimeRange
                label='Agendar horário'
                value={{
                  enabled: values?.scheduled,
                  startTime: values?.startTime,
                  endTime: values?.endTime,
                }}
                onChange={(n) => {
                  setFieldValue('scheduled', n.enabled)
                  setFieldValue('startTime', n.startTime)
                  setFieldValue('endTime', n.endTime)
                }}
                error={errors.scheduled}
              />
              <InputCheckNumber
                label='Limitar quantidade total por execução'
                value={{
                  enabled: values?.limitedSimulation,
                  number: values?.simulationLimit,
                }}
                onChange={(n) => {
                  setFieldValue('limitedSimulation', n.enabled)
                  setFieldValue('simulationLimit', n.number)
                }}
                error={errors.scheduled}
              />*/}
              <InputCheckSelect
                label='Cadastrar proposta'
                value={{
                  enabled: values?.createProposal,
                  id: values?.integrationAuthId,
                }}
                list={integrationList}
                onChange={(n) => {
                  setFieldValue('createProposal', n.enabled)
                  setFieldValue('integrationAuthId', n.enabled ? n.id : null)
                }}
                error={values?.createProposal && !values?.integrationAuthId && "Selecione"}
              />
              <HStack alignItems={'center'}>
                <Text as={'b'} textAlign={'right'} w={200}>Base</Text>
                <Button colorScheme='blue' onClick={() => onOpenModalCreateBatch()}>Importar</Button>
                <Button colorScheme='green' onClick={baseDownload}>Exportar</Button>
                {!robot?.id && !leadsToImport.length ? <AlertHelper message="Importe uma base"></AlertHelper> : null }
                {!robot?.id && leadsToImport.length ?<AlertHelper component={<Text><b>{leadsToImport.length}</b> leads para simular</Text>}/> : null}
                {robot?.id && robot.batchLength ?<AlertHelper component={<Text><b>{leadsToImport.length || robot.batchLength}</b> leads para simular</Text>}/> : null}
              </HStack>
            </SimpleGrid>
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Flex w={'100%'} gap={2}>
            {loadingImporting ? <Flex w={'100%'} gap={2}>
                <Center>
                  <Progress colorScheme='blue' h='10px' w='300px'
                            value={importPercent} />
                  <Text ml={3}>{importPercent}%</Text>
                </Center>
              </Flex>
              : <>
                {robot?.id ?
                  <Button rightIcon={<BiRefresh />} colorScheme='yellow'
                          onClick={() => updateStatus(RobotStatusType.RUNNING)} size={'md'}
                          isLoading={loadingUpdate} isDisabled={dirty}>
                    Reiniciar
                  </Button> : null}
                {robot?.id && robot?.status === RobotStatusType.STOPPED ?
                  <Button rightIcon={<FaPlay />} colorScheme='green'
                          onClick={() => updateStatus(RobotStatusType.RUNNING)} size={'md'}
                          isLoading={loadingUpdate} isDisabled={dirty}>
                    Iniciar
                  </Button> : null}
                {robot?.id && robot?.status !== RobotStatusType.STOPPED ?
                  <Button rightIcon={<TbHandStop />} colorScheme='yellow'
                          onClick={() => updateStatus(RobotStatusType.STOPPED)} size={'md'}
                          isLoading={loadingUpdate} isDisabled={dirty}>
                    Parar
                  </Button> : null}
                {robot?.id ? <ConfirmationButton onConfirm={remove}>
                  <Button rightIcon={<BiTrashAlt />} colorScheme='red' size={'md'} isLoading={loadingUpdate}>
                    Remover
                  </Button>
                </ConfirmationButton> : null}
              </>}
            <Spacer />
            <HStack>
              <Button isDisabled={loadingUpdate || loadingImporting} colorScheme='gray' mr={3} onClick={() => onClose(false)}>
                Fechar
              </Button>
              <Spacer />
              <Button isLoading={loadingUpdate || loadingImporting} isDisabled={!robot?.id && !leadsToImport.length} colorScheme='blue' mr={3} onClick={() => handleSubmit()}>
                Salvar
              </Button>
            </HStack>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );


}

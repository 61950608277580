import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wesendApi } from '../../../services/api/wesend.api';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { RobotFilterInput, RobotStatusType } from '../../../models/robot.model';
import { CreateLeadDto, ImportLeadResponseDto } from '../../../models/lead.model';
import { showToast } from '../../../services/toast.service';
import { initImportation, leadsSlice } from '../leads/leads.api';
import { getCompanySubscription } from '../company/company.slice';
import { CleaningRobot } from '../../../models/cleaningRobot.model';
import { CreateCleaningRobotDto } from '../../../services/api/cleaningRobot/cleaningRobot.dto.wesendapi';

export interface CleaningRobotsState {
  robots: Array<CleaningRobot>;
  loading: boolean;
  loadingUpdate: boolean;
  loadingImporting: boolean;
  error: string | null;
  pagination: PaginatedMetaData | undefined;
  importPercent: number;
  importResult: ImportLeadResponseDto | null;
}

const initialState: CleaningRobotsState = {
  robots: [],
  importResult: null,
  importPercent: 0,
  loading: false,
  error: null,
  loadingUpdate: false,
  loadingImporting: false,
  pagination: {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 20,
    prev: 0,
    next: 0,
  },
};

export const getCleaningRobots = createAsyncThunk<{ robots: Array<CleaningRobot>, pagination: PaginatedMetaData },{ page: number, filter: RobotFilterInput}>(
  'cleaningRobots/list',
  async (params, {dispatch, getState}) => {
    const robots = await wesendApi.cleaningRobot.list({
      ...params.filter,
      page: params.page,
    });
    return { robots: robots.data, pagination: robots.meta };
  },
);

export const refreshCleaningRobots = createAsyncThunk<{ robots: Array<CleaningRobot>, pagination: PaginatedMetaData },{ page: number, filter: RobotFilterInput}>(
  'cleaningRobots/refresh',
  async (params, {dispatch, getState}) => {
    const robots = await wesendApi.cleaningRobot.list({
      ...params.filter,
      page: params.page,
    });
    return { robots: robots.data, pagination: robots.meta };
  },
);

export const removeCleaningRobot = createAsyncThunk<{ success: boolean },{ id: string }>(
  'cleaningRobots/remove',
  async (params, {dispatch, getState}) => {
    await wesendApi.cleaningRobot.delete(params.id);
    dispatch(getCompanySubscription());
    return { success: true };
  },
);

export const changeCleaningRobotStatus = createAsyncThunk<{ success: boolean },{ id: string, status: RobotStatusType }>(
  'cleaningRobots/changeStatus',
  async (params, {dispatch, getState}) => {
    await wesendApi.cleaningRobot.changeStatus(params.id, params.status);
    return { success: true };
  },
);

export const createCleaningRobot = createAsyncThunk<CleaningRobot, CreateCleaningRobotDto>(
  'cleaningRobots/create',
  async (robot, {dispatch, getState}) => {
    dispatch(initImportation());
    dispatch(getCompanySubscription());
    return await wesendApi.cleaningRobot.create(robot);
  },
);

export const editCleaningRobot = createAsyncThunk<CleaningRobot, CreateCleaningRobotDto>(
  'cleaningRobots/edit',
  async (robot, {dispatch, getState}) => {
    dispatch(initImportation());
    return await wesendApi.cleaningRobot.edit(robot);
  },
);

export const createBatchcleaningRobot = createAsyncThunk<string, { id: string, leads: Array<CreateLeadDto>}>(
  'cleaningRobots/createBatchcleaningRobot',
  async (_, {dispatch, getState}) => {
    return await wesendApi.cleaningRobot.createBatchRobot(_.id, _.leads);
  },
);

export const exportCleaningRobotBatch = createAsyncThunk<string, string>(
  'cleaningRobots/exportBatch',
  async (id, {dispatch, getState}) => {
    const csv = await wesendApi.cleaningRobot.exportBatch(id);
    return csv;
  },
);

export const getImportStatus = createAsyncThunk<ImportLeadResponseDto, string>(
  'cleaningRobots/importStatus',
  async (importId, { getState }) => {
    return await wesendApi.lead.importStatus(importId);
  });

export const cleaningRobotSlice = createSlice({
  name: 'CleaningRobots',
  initialState,
  reducers: {
    clearCleaningRobotResult: (state) => {
      state.importResult = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCleaningRobots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCleaningRobots.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.robots = action.payload.robots;
        state.pagination = action.payload.pagination;
      })
      .addCase(getCleaningRobots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(refreshCleaningRobots.fulfilled, (state, action) => {
        state.error = null;
        state.robots = action.payload.robots;
        state.pagination = action.payload.pagination;
      })
      .addCase(refreshCleaningRobots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(removeCleaningRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(removeCleaningRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(removeCleaningRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(changeCleaningRobotStatus.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(changeCleaningRobotStatus.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(changeCleaningRobotStatus.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(createCleaningRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(createCleaningRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(createCleaningRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(editCleaningRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(editCleaningRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(editCleaningRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(createBatchcleaningRobot.pending, (state) => {
        state.loadingImporting = true;
      })
      .addCase(createBatchcleaningRobot.fulfilled, (state, action) => {
        state.error = null;
      })
      .addCase(createBatchcleaningRobot.rejected, (state, action) => {
        state.loadingImporting = false;
        state.error = action.error.message;
      })
      .addCase(exportCleaningRobotBatch.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(exportCleaningRobotBatch.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(exportCleaningRobotBatch.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(getImportStatus.pending, (state) => {
      })
      .addCase(getImportStatus.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingImporting = false;
      })
      .addCase(getImportStatus.fulfilled, (state, action) => {
        state.error = null;
        state.importPercent = parseInt(String(action.payload.completed / action.payload.size * 100), 10);
        if (state.importPercent === 100) {
          state.importResult = action.payload;
          showToast('success', 'Base importada!');
          state.loadingImporting = false;
        }
      })
  },
});

export const {clearCleaningRobotResult} = cleaningRobotSlice.actions;

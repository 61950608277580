import { BankType, RobotStatusType } from './robot.model';

export enum LeadCleaningStatus {
  UPDATED = 'UPDATED',
  NEW = 'NEW',
  UNAVAILABLE = 'UNAVAILABLE',
  FAIL = 'FAIL',
}

export type CleaningRobot = {
  id: string
  type: BankType | null
  name: string
  errorAttemps: number
  login: string
  password: string
  status: RobotStatusType
  reference: number
  simulationsLength: number | null
  simulationsUnavailableLength: number | null
  simulationsFailLength: number | null
  simulationsUpdatedLength: number | null
  scheduled: boolean
  startTime: Date | null
  endTime: Date | null
  limitedSimulation: boolean
  simulationLimit: number
  concurrency: number
  deployName: string | null
  serviceId: string | null
  startedAt: Date | null
  stoppedAt: Date | null
  batchLength: number
  retryFails: boolean
  actualBatchId: string | null
}

export const CLEANING_ROBOTS_BANKS: Array<BankType> = [BankType.FACTA];

export const cleaningStatusName = {
  [LeadCleaningStatus.FAIL]: 'FALHA',
  [LeadCleaningStatus.NEW]: 'NOVO',
  [LeadCleaningStatus.UNAVAILABLE]: 'INDISPONÍVEL',
  [LeadCleaningStatus.UPDATED]: 'HIGIENIZADO',
};

import { Box, Button, HStack, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import ChannelsTable from './components/ChannelsTable';
import { AddIcon } from '@chakra-ui/icons';
import { FiRefreshCcw } from 'react-icons/fi';
import { getChannels, refreshChannels } from '../../store/features/channel/channel.slice';
import ChannelFilterCmp from 'components/channelFilter';
import { Channel, ChannelFilter } from '../../models/channel.model';
import { ModalChannel } from './components/ModalChannel';
import EmptyState from '../../components/emptyState/emptyState.component';
import { canCreateModule } from '../../store/features/company/company.slice';
import { CompanySubscriptionModuleType } from '../../models/company.model';

export default function Channels() {

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { channels, loading, error, pagination } = useAppSelector((state: RootState) => state.channel);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<ChannelFilter>({});
  const [isFiltering, setIsfiltering] = useState<boolean>(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(refreshChannels({ page, filter }));
    }, 3000);
    return () => clearInterval(intervalId);
  }, [filter, page]);

  useEffect(() => {
    dispatch(getChannels({ page, filter }));
  }, [page]);

  useEffect(() => {
    dispatch(getChannels({ page, filter }));
  }, [filter]);

  function updateFilter(newFilter: ChannelFilter) {
    setFilter(newFilter);
    if (newFilter && !Object.keys(newFilter).length) {
      setIsfiltering(false);
    }
  }

  useEffect(() => {
    if (selectedChannel)
      onToggle();
  }, [selectedChannel]);

  function closeDrawerChannel(updated: boolean) {
    setSelectedChannel(undefined);
    onClose();
    if (updated) {
      dispatch(getChannels({ page, filter }));
    }
  }

  function refresh() {
    dispatch(getChannels({ page, filter }));
  }

  function create() {
    dispatch(canCreateModule({
        module: CompanySubscriptionModuleType.CHANNEL,
        fn: () => {
          onToggle();
        },
      },
    ));
  }

  return (
    <Box mt={{ base: '120px', md: '80px' }}>
      <HStack alignItems={'center'} mb={5} gap={10}>
        {!isFiltering ?
          <Button ml={5} rightIcon={<FiRefreshCcw />} colorScheme='blue' variant='link' onClick={refresh}
                  isLoading={loading}>
            Atualizar
          </Button> : null}
        <ChannelFilterCmp onFilter={updateFilter} loading={loading} onClick={() => setIsfiltering(true)} />
        {!isFiltering ? <Button rightIcon={<AddIcon />} colorScheme='blue' onClick={create} size={'sm'}>
          Novo
        </Button> : null}
      </HStack>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <EmptyState text={"Nenhum canal criado"} empty={channels && !channels.length} loading={loading} filtering={isFiltering} textFilter={'Nenhum canal encontrado'}>
          <ChannelsTable
            pagination={pagination}
            tableData={channels}
            onSelect={(l) => setSelectedChannel(l)}
            onPageChange={v => setPage(v)}
            loading={loading}
          />
        </EmptyState>
      </SimpleGrid>
      {isOpen ? <ModalChannel channel={selectedChannel} isOpen={isOpen}
                              onClose={(updated: boolean) => closeDrawerChannel(updated)} /> : null}
    </Box>
  );
}

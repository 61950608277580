export enum IntegrationAuthType {
  MEU_CORBAN = 'MEU_CORBAN',
}

export const IntegrationAuthTypeTranslation: {[key in IntegrationAuthType]: string} = {
  [IntegrationAuthType.MEU_CORBAN]: 'Meu Corban',
}

export type IntegrationAuth = {
  id: string
  name: string;
  type: IntegrationAuthType;
  extraAuth: string;
  login: string
  password: string
}

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import IntegrationsTable from './IntegrationsTable';
import { ModalIntegration } from './ModalIntegration';
import { getIntegrations } from '../../store/features/integration/integration.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { IntegrationAuth } from '../../models/integrationAuth.model';
import { RootState } from '../../store/store';
import { AddIcon } from '@chakra-ui/icons';
import EmptyState from '../emptyState/emptyState.component';

interface Imodal {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalIntegrations({ isOpen, onClose }: Imodal) {
  const { isOpen: modalIsOpen, onOpen: modalOnOpen, onClose: modalOnClose, onToggle: modalOnToggle } = useDisclosure();
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationAuth | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { integrations, loading, error, pagination } = useAppSelector((state: RootState) => state.integration);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getIntegrations({ page }));
  }, [page]);

  useEffect(() => {
    if (selectedIntegration)
      modalOnToggle();
  }, [selectedIntegration]);

  function closeDrawerRobot(updated: boolean) {
    setSelectedIntegration(undefined);
    modalOnClose();
    if (updated) {
      dispatch(getIntegrations({ page }));
    }
  }

  function create() {
    modalOnToggle();
  }

  return (
    <Modal onClose={onClose} size={'5xl'} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Integrações</ModalHeader>
        <HStack mr={5}>
          <Spacer/>
          <Button rightIcon={<AddIcon />} colorScheme='blue' onClick={create} size={'sm'}>
            Novo
          </Button>
        </HStack>
        <ModalCloseButton />
        <ModalBody>
          <EmptyState text={''} empty={integrations && !integrations.length} loading={loading} textFilter={'Nenhum robô encontrado'}>
            <IntegrationsTable
              tableData={integrations}
              onSelect={(i) => setSelectedIntegration(i)}
              onPageChange={setPage}
              pagination={pagination}
              loading={loading}
            />
          </EmptyState>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
        </ModalFooter>
      </ModalContent>
      {isOpen ? <ModalIntegration integration={selectedIntegration} isOpen={modalIsOpen}
                                  onClose={(updated: boolean) => closeDrawerRobot(updated)} /> : null}
    </Modal>
  );
}

import { Alert, AlertDescription, AlertIcon, Flex, FormControl, HStack, Switch, Text } from '@chakra-ui/react';
import React from 'react';
import { InputSelect } from '../inputSelect/inputSelect.component';

interface IInputCheckTimeRangeValue {
  enabled: boolean;
  id: string;
}

interface IInputCheckTimeRangeComponent {
  error?: string;
  onChange: (value: IInputCheckTimeRangeValue) => void;
  list: Array<{ id: string, label: string, tag: string }>;
  label?: string;
  disabled?: boolean;
  value: IInputCheckTimeRangeValue;
}

export function InputCheckSelect(
  {
    error,
    onChange,
    value,
    label,
    disabled,
    list,
  }: IInputCheckTimeRangeComponent) {
  return (
    <HStack alignItems={'center'}>
      {label ? <Text as={'b'} textAlign={'right'} w={200}>{label}</Text> : null}
      <FormControl isInvalid={!!error} maxW={30}>
        <Switch
          size='lg'
          isChecked={value.enabled}
          isDisabled={!!disabled}
          onChange={(e) => {
            onChange({
              enabled: e.target.checked,
              id: value.id,
            });
          }}
        />
      </FormControl>
      {value.enabled ?
      <Flex align={'center'} gap={5}>
        <InputSelect
          key={'bank-table'}
          label='Integração'
          placeholder='Selecione'
          value={value.id}
          onChange={(n) => {
            onChange({
              enabled: value.enabled,
              id: n,
            })
          }}
          options={list.map(v => {
            return {
              label: `(${v.tag}) ${v.label}`,
              value: v.id,
            };
          })}
        />
      </Flex> : null}
      {!!error && <Alert status='error' maxW={250}>
        <AlertIcon />
        <AlertDescription fontSize={'sm'}>{error}</AlertDescription>
      </Alert>}
    </HStack>
  );
}

export interface CompanySubscriptionModulesCount {
  sms: number;
  balanceRobot: number;
  typingRobot: number;
  channel: number;
  cleaningRobot: number;
}

export interface CompanySubscription {
  using: CompanySubscriptionModulesCount,
  available: CompanySubscriptionModulesCount,
}

export enum CompanySubscriptionModuleType {
  BALANCE_ROBOT = 'balanceRobot',
  SMS = 'sms',
  TYPING_ROBOT = 'typingRobot',
  CHANNEL = 'channel',
  CLEANING_ROBOT = 'cleaningRobot'
}

export const CompanySubscriptionModuleTypeName = {
  [CompanySubscriptionModuleType.BALANCE_ROBOT]: 'Robô de consulta',
  [CompanySubscriptionModuleType.TYPING_ROBOT]: 'Robô de digitação',
  [CompanySubscriptionModuleType.CLEANING_ROBOT]: 'Robô de Higienização',
  [CompanySubscriptionModuleType.CHANNEL]: 'Canais do WhatsApp',
  [CompanySubscriptionModuleType.SMS]: 'Saldo de SMS',
};

export interface CompanyIndicatorsDto {
  leadsCount: number;
  leadsOpportunities: number;
  sellOpportunities: number;
}

export const WA_SUPPORT_LINK = 'https://wa.me/5548999751334?text=Ol%C3%A1';

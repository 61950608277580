import { BankType } from '../../models/robot.model';
import { CreateLeadDto } from '../../models/lead.model';

// Mapeamento de colunas com valores padrão
export const typingRobotImportMap: {
  [key in keyof CreateLeadDto]?: { column: string; defaultValue: any };
} = {
  name: { column: 'Nome', defaultValue: null },
  cpf: { column: 'CPF', defaultValue: null },
  phone: { column: 'Telefone', defaultValue: null },
  sexo: { column: 'Sexo', defaultValue: null },
  estadoCivil: { column: 'Estado Civil', defaultValue: 6 },
  dataNascimento: { column: 'Data de Nascimento', defaultValue: null },
  rg: { column: 'RG', defaultValue: 'gerar' },
  estadoRg: { column: 'Estado RG', defaultValue: 'gerar' },
  orgaoEmissor: { column: 'Órgão Emissor', defaultValue: 'SSP' },
  dataExpedicao: { column: 'Data Expedição', defaultValue: '01/10/2000' },
  estadoNatural: { column: 'Estado Natural', defaultValue: 'default' },
  cidadeNatural: { column: 'Cidade Natural', defaultValue: 'default' },
  nacionalidade: { column: 'Nacionalidade', defaultValue: 1 },
  paisOrigem: { column: 'País Origem', defaultValue: 'BR' },
  renda: { column: 'Renda', defaultValue: '2000' },
  cep: { column: 'CEP', defaultValue: null },
  endereco: { column: 'Endereço', defaultValue: null },
  numero: { column: 'Número do Endereço', defaultValue: null },
  complemento: { column: 'Complemento', defaultValue: 'default' },
  bairro: { column: 'Bairro', defaultValue: null },
  cidade: { column: 'Cidade', defaultValue: null },
  estado: { column: 'Estado', defaultValue: null },
  nomeMae: { column: 'Nome da Mãe', defaultValue: null },
  nomePai: { column: 'Nome do Pai', defaultValue: 'Não informado' },
  valorPatrimonio: { column: 'Valor Patrimônio', defaultValue: 2000 },
  clienteIletradoImpossibilitado: { column: 'Cliente Iletrado', defaultValue: 'N' },
  banco: { column: 'Banco de Liberação', defaultValue: null },
  agencia: { column: 'Agência de Liberação', defaultValue: null },
  conta: { column: 'Conta de Liberação', defaultValue: null },
  contaDigito: { column: 'Conta Dígito', defaultValue: 'default' },
  tipoConta: { column: 'Tipo Conta', defaultValue: 'default' },
  email: { column: 'E-mail', defaultValue: 'default' },
  idConvenio: { column: 'Convênio', defaultValue: null },
  idBanco: { column: 'Banco', defaultValue: null },
  idProduto: { column: 'Produto', defaultValue: null },
  idPromotora: { column: 'Promotora', defaultValue: null },
  idVendedor: { column: 'Vendedor', defaultValue: null },
  idFranquia: { column: 'Franquia', defaultValue: '' },
};

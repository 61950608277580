import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wesendApi } from '../../../services/api/wesend.api';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { RobotFilterInput, RobotStatusType } from '../../../models/robot.model';
import { TypingRobot } from '../../../models/typingRobot.model';
import { CreateLeadDto, ImportLeadResponseDto } from '../../../models/lead.model';
import { getCompanySubscription } from '../company/company.slice';

export interface TypingRobotsState {
  robots: Array<TypingRobot>;
  loading: boolean;
  loadingUpdate: boolean;
  error: string | null;
  loadingImporting: boolean;
  pagination: PaginatedMetaData | undefined;
  importPercent: number;
  importResult: ImportLeadResponseDto | null;
}

const initialState: TypingRobotsState = {
  robots: [],
  loading: false,
  loadingImporting: false,
  importResult: null,
  importPercent: 0,
  error: null,
  loadingUpdate: false,
  pagination: {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 20,
    prev: 0,
    next: 0,
  },
};

export const getTypingRobots = createAsyncThunk<{ robots: Array<TypingRobot>, pagination: PaginatedMetaData },{ page: number, filter: RobotFilterInput}>(
  'typingRobot/list',
  async (params, {dispatch, getState}) => {
    const robots = await wesendApi.typingRobot.list({
      ...params.filter,
      page: params.page,
    });
    return { robots: robots.data, pagination: robots.meta };
  },
);

export const refreshTypingRobots = createAsyncThunk<{ robots: Array<TypingRobot>, pagination: PaginatedMetaData },{ page: number, filter: RobotFilterInput}>(
  'typingRobot/refresh',
  async (params, {dispatch, getState}) => {
    const robots = await wesendApi.typingRobot.list({
      ...params.filter,
      page: params.page,
    });
    return { robots: robots.data, pagination: robots.meta };
  },
);

export const removeTypingRobot = createAsyncThunk<{ success: boolean },{ id: string }>(
  'typingRobot/remove',
  async (params, {dispatch, getState}) => {
    await wesendApi.typingRobot.delete(params.id);
    dispatch(getCompanySubscription());
    return { success: true };
  },
);

export const changeTypingRobotStatus = createAsyncThunk<{ success: boolean },{ id: string, status: RobotStatusType }>(
  'typingRobot/changeStatus',
  async (params, {dispatch, getState}) => {
    await wesendApi.typingRobot.changeTypingRobotStatus(params.id, params.status);
    return { success: true };
  },
);

export const createTypingRobot = createAsyncThunk<TypingRobot, TypingRobot>(
  'typingRobot/create',
  async (robot, {dispatch, getState}) => {
    dispatch(getCompanySubscription());
    return await wesendApi.typingRobot.create(robot);
  },
);

export const editTypingRobot = createAsyncThunk<TypingRobot, TypingRobot>(
  'typingRobot/edit',
  async (robot, {dispatch, getState}) => {
    return await wesendApi.typingRobot.editTypingRobot(robot);
  },
);

export const createTypingBatchCampaing = createAsyncThunk<string, { id: string, leads: Array<CreateLeadDto>}>(
  'typingRobot/createBatchCampaing',
  async (_, {dispatch, getState}) => {
    return await wesendApi.typingRobot.createBatchTypingRobot(_.id, _.leads);
  },
);

export const exportTypingBatch = createAsyncThunk<ArrayBuffer, string>(
  'typingRobot/exportCampaingBatch',
  async (id, {dispatch, getState}) => {
    const csv = await wesendApi.typingRobot.exportBatch(id);
    return csv;
  },
);

export const getModelData = createAsyncThunk<string>(
  'typingRobot/getModelData',
  async (id, {dispatch, getState}) => {
    const csv = await wesendApi.typingRobot.getModelData();
    return csv;
  },
);

export const getImportStatus = createAsyncThunk<ImportLeadResponseDto, string>(
  'typingRobot/importStatus',
  async (importId, { getState }) => {
    return await wesendApi.lead.importStatus(importId);
  });

export const typingRobotsSlice = createSlice({
  name: 'TypingRobot',
  initialState,
  reducers: {
    clearTypingImportationResult: (state) => {
      state.importResult = null;
      state.loadingImporting = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTypingRobots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTypingRobots.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.robots = action.payload.robots;
        state.pagination = action.payload.pagination;
      })
      .addCase(getTypingRobots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(refreshTypingRobots.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.robots = action.payload.robots;
        state.pagination = action.payload.pagination;
      })
      .addCase(refreshTypingRobots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(removeTypingRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(removeTypingRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(removeTypingRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(changeTypingRobotStatus.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(changeTypingRobotStatus.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(changeTypingRobotStatus.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(createTypingRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(createTypingRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(createTypingRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(editTypingRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(editTypingRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(editTypingRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(createTypingBatchCampaing.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(createTypingBatchCampaing.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(createTypingBatchCampaing.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(exportTypingBatch.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(exportTypingBatch.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(exportTypingBatch.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(getModelData.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(getModelData.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(getModelData.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(getImportStatus.pending, (state) => {
        state.loadingImporting = true;
      })
      .addCase(getImportStatus.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingImporting = false;
      })
      .addCase(getImportStatus.fulfilled, (state, action) => {
        state.error = null;
        state.importPercent = parseInt(String(action.payload.completed / action.payload.size * 100), 10);
        if (state.importPercent === 100) {
          state.importResult = action.payload;
          state.loadingImporting = false;
        }
      })
  },
});
export const {clearTypingImportationResult} = typingRobotsSlice.actions;

import {
  Alert,
  AlertDescription,
  AlertIcon,
  FormControl,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react';
import React from 'react';

interface IInputNumberComponent {
  error?: string;
  value: number;
  onChange: (value: number) => void;
  label?: string;
  min: number;
  max: number;
  disabled?: boolean;
  witdh?: number;
  precision?: number;
  step?: number;
}

export function InputNumber(
  {
    error,
    onChange,
    value,
    label,
    min,
    max,
    disabled,
    witdh,
    precision,
    step,
  }: IInputNumberComponent) {
  return (
    <HStack alignItems={'center'}>
      {label ? <Text as={'b'} textAlign={'right'} w={200}>{label}</Text>: null}
      <FormControl isInvalid={!!error} maxW={300}>
        <NumberInput
          w={witdh || 200}
          min={min}
          max={max}
          onChange={(e) => onChange(parseFloat(e))}
          value={value}
          precision={precision || 0}
          step={step || 1}
          isDisabled={!!disabled}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      {!!error && <Alert status='error' maxW={250}>
        <AlertIcon />
        <AlertDescription fontSize={'sm'}>{error}</AlertDescription>
      </Alert>}
    </HStack>
  );


}
